import React from 'react'
import accounting from 'accounting'

interface PriceProps {
  amount: number
  decimalSup?: boolean
  decimal?: number
}

const Price: React.FC<PriceProps> = ({
  amount,
  decimalSup = false,
  decimal = 0,
}) => {
  if (!amount) {
    //return null;
  }
  if (decimalSup) {
    const splitPrice = accounting
      .formatMoney(amount, '€ ', decimal, '.', ',')
      .split(',')
    return (
      <span className="whitespace-nowrap">
        {splitPrice[0]},<sup>{splitPrice[1]}</sup>
      </span>
    )
  }
  return (
    <span className="whitespace-nowrap">
      {accounting.formatMoney(amount, '€ ', decimal, '.', ',')}
    </span>
  )
}

export default Price
