const TIPI_APPARTAMENTO = {
  1: 'Monolocale',
  2: 'Bilocale',
  3: 'Trilocale',
  4: 'Quadrilocale',
}

const getTipoImmobile = (tipologia: string, numeroLocali: number) => {
  if (tipologia === 'Appartamento') {
    return TIPI_APPARTAMENTO[numeroLocali] ? TIPI_APPARTAMENTO[numeroLocali] : 'Plurilocale'
  }
  return tipologia
}

export default getTipoImmobile
