import React, { Fragment, useContext, useEffect, useState } from 'react'
import { navigate } from 'gatsby'

import { Immobile } from '../lib/types'

// import ErrorNoHomePage from '../components/errorNoHomePage'
// import ErrorNoKeys from '../components/errorNoKeys'
import FilterSelect from '../components/FilterSelect'
import Layout from '../components/layout'
import Seo from '../components/seo'
import Thumbnail from '../components/Thumbnail'

interface HomeProps {
  pageContext: {
    immobili: Immobile[]
    tipologieOptions: { name: string; value: string }[]
    comuniOptions: { name: string; value: string }[]
    servizi: { value: string; label: string }[]
    params?: { comune?: { name: string; value: string }; tipologia?: { name: string; value: string } }
  }
}

interface Option {
  name: string
  value: string
}

const prezziMinOptions: Option[] = [
  { name: 'Qualsiasi prezzo min', value: '' },
  { name: 'Min € 50.000', value: '50000' },
  { name: 'Min € 100.000', value: '100000' },
  { name: 'Min € 150.000', value: '150000' },
  { name: 'Min € 200.000', value: '200000' },
  { name: 'Min € 250.000', value: '250000' },
  { name: 'Min € 300.000', value: '300000' },
  { name: 'Min € 400.000', value: '400000' },
]
const prezziMaxOptions: Option[] = [
  { name: 'Qualsiasi prezzo max', value: '' },
  { name: 'Max € 50.000', value: '50000' },
  { name: 'Max € 100.000', value: '100000' },
  { name: 'Max € 150.000', value: '150000' },
  { name: 'Max € 200.000', value: '200000' },
  { name: 'Max € 250.000', value: '250000' },
  { name: 'Max € 300.000', value: '300000' },
  { name: 'Max € 400.000', value: '400000' },
]

const Home: React.FC<HomeProps> = ({
  pageContext: { immobili, tipologieOptions, comuniOptions, servizi, params },
  ...rest
}) => {
  const tipologiaFromLocalStorage = typeof window === 'undefined' ? '' : localStorage.getItem('tipologia')
  const comuneFromLocalStorage = typeof window === 'undefined' ? '' : localStorage.getItem('comune')

  const [selectedTipologia, setSelectedTipologia] = useState({
    name: params?.tipologia?.name || tipologiaFromLocalStorage || 'Qualsiasi tipologia',
    value: params?.tipologia?.value || tipologiaFromLocalStorage || '',
  })

  const [selectedComune, setSelectedComune] = useState({
    name: params?.comune?.name || comuneFromLocalStorage || 'Qualsiasi comune',
    value: params?.comune?.value || comuneFromLocalStorage || '',
  })

  const [selectedPrezzoMin, setSelectedPrezzoMin] = useState(prezziMinOptions[0])
  const [selectedPrezzoMax, setSelectedPrezzoMax] = useState(prezziMaxOptions[0])

  const handleSetSelectedTipologia = (event) => {
    setSelectedTipologia(event.value)
    if (event.value && selectedComune.value) {
      navigate(`/immobili/${event.value}-${selectedComune.value}`)
    }
    if (selectedComune.value && !event.value) {
      navigate(`/immobili/${selectedComune.value}`)
    }
    if (!selectedComune.value && event.value) {
      navigate(`/immobili/${event.value}`)
    }
    if (!event.value && !selectedComune.value) {
      navigate(`/`)
    }
  }

  const handleSetSelectedComune = (event) => {
    setSelectedComune(event.value)
    if (selectedTipologia.value && event.value) {
      navigate(`/immobili/${selectedTipologia.value}-${event.value}`)
    }
    if (event.value && !selectedTipologia.value) {
      navigate(`/immobili/${event.value}`)
    }
    if (!event.value && selectedTipologia.value) {
      navigate(`/immobili/${selectedTipologia.value}`)
    }
    if (!selectedTipologia.value && !event.value) {
      navigate(`/`)
    }
  }

  return (
    <Layout servizi={servizi}>
      <>
        <Seo title={'I nostri immobili'} description={'Immobili Casa Dalmine'} />
        <div className="max-w-7xl mx-auto px-6 py-12">
          <h1 className="mx-auto text-4xl font-semibold text-center mb-10 text-gray-900 dark:text-gray-100">
            I nostri immobili
          </h1>
          <div className="flex flex-wrap items-baseline justify-between mb-8 mx-4 p-6 bg-gray-100 dark:bg-gray-600 rounded border dark:border-gray-600">
            <div className="text-gray-700 dark:text-gray-100">
              Filtra<span className="md:hidden lg:inline"> i risultati</span>
            </div>
            <div className="w-full md:w-1/5">
              <FilterSelect
                itemName="tipologia"
                options={tipologieOptions}
                selectedOption={selectedTipologia}
                setSelectedOption={handleSetSelectedTipologia}
              />
            </div>
            <div className="w-full md:w-1/5">
              <FilterSelect
                itemName="comune"
                options={comuniOptions}
                selectedOption={selectedComune}
                setSelectedOption={handleSetSelectedComune}
              />
            </div>
            <div className="w-full md:w-1/5">
              <FilterSelect
                options={prezziMinOptions}
                selectedOption={selectedPrezzoMin}
                setSelectedOption={setSelectedPrezzoMin}
              />
            </div>
            <div className="w-full md:w-1/5">
              <FilterSelect
                options={prezziMaxOptions}
                selectedOption={selectedPrezzoMax}
                setSelectedOption={setSelectedPrezzoMax}
              />
            </div>
          </div>
          <div className="flex flex-wrap justify-center mx-auto">
            {immobili && immobili.length > 0 ? (
              immobili
                //filtro basato sul prezzo
                .filter((imm) => {
                  if (isNaN(parseInt(selectedPrezzoMin.value)) && isNaN(parseInt(selectedPrezzoMax.value))) {
                    return imm.prezzo >= 0 && imm.prezzo <= Infinity
                  }
                  if (isNaN(parseInt(selectedPrezzoMax.value))) {
                    return imm.prezzo <= Infinity && imm.prezzo >= parseInt(selectedPrezzoMin.value)
                  }
                  if (isNaN(parseInt(selectedPrezzoMin.value))) {
                    return imm.prezzo <= parseInt(selectedPrezzoMax.value) && imm.prezzo >= 0
                  }

                  return (
                    imm.prezzo <= parseInt(selectedPrezzoMax.value) && imm.prezzo >= parseInt(selectedPrezzoMin.value)
                  )
                })
                .map((immobile) => {
                  return (
                    <Fragment key={immobile.immobileID}>
                      <Thumbnail immobile={immobile} />
                      <Thumbnail immobile={immobile} mobile />
                    </Fragment>
                  )
                })
            ) : (
              <h1 className="py-10 text-3xl text-semibold text-gray-900 dark:text-gray-100">
                Non ci sono immobili corrispondenti a questi criteri di ricerca
              </h1>
            )}
          </div>
        </div>
      </>
    </Layout>
  )
}

export default Home
