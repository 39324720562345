import React, { useState } from 'react'
import { Link } from 'gatsby'
import clsx from 'clsx'

import { Immobile } from '../lib/types'

import Price from '../components/Price'
import getTipoImmobile from '../lib/getTipoImmobile'

interface ThumbnailProps {
  immobile: Immobile
  mobile?: boolean
}

const Thumbnail: React.FC<ThumbnailProps> = ({ immobile, mobile = false }) => {
  const [showDetail, setShowDetail] = useState<string>('')

  const numeroLocali = +immobile.datiImmobile.find((dato) => dato.etichetta.toLowerCase() === 'n° locali').valore

  return (
    <Link
      to={`/immobile/${getTipoImmobile(
        immobile.tipologia,
        numeroLocali
      ).toLowerCase()}-${immobile.comune.toLowerCase()}/${immobile.immobileID}`}
      className={clsx('w-full sm:w-1/2 lg:w-1/3 px-4 pb-8', mobile ? 'lg:hidden' : 'hidden lg:block')}
      onMouseEnter={() => setShowDetail(immobile.immobileID)}
      onMouseLeave={() => setShowDetail('')}
    >
      <div className="relative flex items-center justify-center overflow-hidden h-72 rounded">
        <img
          className="block w-full h-72 object-cover"
          src={`${
            immobile.fotografie.length && immobile.fotografie[0].urlFoto
              ? immobile.fotografie[0].urlFoto
              : '/no-photo.png'
          }`}
          alt={`${getTipoImmobile(immobile.tipologia, numeroLocali)} a ${immobile.comune} - cod. ${immobile.codice}`}
        />
        <div
          className="absolute top-0 right-0 text-white text-sm text-right py-2 px-4 rounded-bl shadow-md"
          style={{
            backgroundImage: 'linear-gradient(to right, rgba(30, 58, 138, 0.75), rgba(30, 58, 138, 1))',
          }}
        >
          <span className="text-xs text-blue-100 mr-2">cod.</span>
          {immobile.codice}
        </div>
        <div
          className={clsx(
            'absolute top-16 bottom-0 left-0 right-0 bg-gradient-to-t from-black flex items-end w-full rounded transition-all duration-300',
            showDetail === immobile.immobileID || mobile ? 'translate-y-0' : 'translate-y-14'
          )}
          style={{ textShadow: '0 0 10px rgba(0,0,0,0.3)' }}
        >
          <div className="w-full">
            <div className="px-4 text-white transition-all duration-300 pb-4">
              <div className="text-lg mb-1">
                {getTipoImmobile(immobile.tipologia, numeroLocali)} a {immobile.comune}
              </div>
              <b className="text-xl">
                <Price amount={immobile.prezzo} />
              </b>
            </div>
            <div className="flex items-center justify-between bg-red-700 py-2 w-full transition-all duration-300">
              {!!immobile.totaleMq && (
                <div className="flex-1 text-center">
                  <div className="text-white text-md font-semibold">
                    {immobile.totaleMq} <small>mq</small>
                  </div>
                  <div className="text-red-100 text-xs uppercase">Superficie</div>
                </div>
              )}
              {!!numeroLocali && (
                <div className="flex-1 text-center">
                  <div className="text-white text-md font-semibold">{numeroLocali}</div>
                  <div className="text-red-100 text-xs uppercase">Locali</div>
                </div>
              )}
              {!!immobile.camere && (
                <div className="flex-1 text-center">
                  <div className="text-white text-md font-semibold">{immobile.camere}</div>
                  <div className="text-red-100 text-xs uppercase">Camere</div>
                </div>
              )}
              {!!immobile.bagni && (
                <div className="flex-1 text-center">
                  <div className="text-white text-md font-semibold">{immobile.bagni}</div>
                  <div className="text-red-100 text-xs uppercase">Bagni</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}

export default Thumbnail
