import React, { Fragment } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { HiCheck, HiOutlineSelector } from 'react-icons/hi'
import clsx from 'clsx'

interface FilterSelectProps {
  itemName?: string
  options: { name: string; value: string }[]
  selectedOption: { name: string; value: string }
  setSelectedOption: any
}

const FilterSelect: React.FC<FilterSelectProps> = ({ itemName, options, selectedOption, setSelectedOption }) => {
  return (
    <Listbox
      value={selectedOption}
      onChange={(event) => {
        localStorage.setItem(itemName, event.value)
        setSelectedOption(event)
      }}
    >
      <div className="relative mt-1">
        <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 rounded-lg dark:shadow-inner focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-red-300 focus-visible:ring-offset-2 focus-visible:border-blue-500 sm:text-sm border dark:border-transparent">
          <span className="block truncate capitalize">{selectedOption.name}</span>
          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            {selectedOption.value === '' ? (
              <HiOutlineSelector className="w-5 h-5 text-gray-400" aria-hidden="true" />
            ) : (
              <HiCheck className="w-5 h-5 text-blue-400" aria-hidden="true" />
            )}
          </span>
        </Listbox.Button>
        <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
          <Listbox.Options className="absolute w-full py-1 mt-1 overflow-auto text-base bg-white dark:bg-gray-700 rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-10">
            {options.map((option) => (
              <Listbox.Option
                key={option.value}
                className={({ active }) =>
                  clsx(
                    'cursor-default select-none relative py-2 pl-10 pr-4',
                    active
                      ? 'text-gray-900 dark:text-gray-100 bg-gray-100 dark:bg-gray-600'
                      : 'text-gray-900 dark:text-gray-100'
                  )
                }
                value={option}
              >
                {({ selected, active }) => (
                  <>
                    <span className={clsx('block truncate', selected ? 'font-medium' : 'font-normal')}>
                      {option.name}
                    </span>
                    {selected ? (
                      <span
                        className={clsx(
                          'absolute inset-y-0 left-0 flex items-center pl-3',
                          active ? 'text-red-600' : 'text-red-400'
                        )}
                      >
                        <HiCheck className="w-5 h-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  )
}

export default FilterSelect
